<template>
  <div class="login">
    <img src="@/assets/bg.png" class="bg" />
    <img src="@/assets/tit1.png" class="tit1" />
    <img src="@/assets/tit2.png" class="tit2" />
    <div class="form-div">
      <div class="input-item flex between">
        <div>姓名</div>
        <input v-model="name" type="text" placeholder="请输入真实姓名" />
      </div>
      <div class="input-item flex between">
        <div>身份证号</div>
        <input v-model="card" type="text" placeholder="请输入身份证号" />
      </div>
    </div>
    <div class="login-btn" @click="toLogin">登录</div>
    <div class="tips">技术支持：由莲花县大数据中心及南昌云媒科技有限公司提供</div>
  </div>
</template>
<script>
import { login } from "@/api/apiData";
import { Toast } from "vant";
export default {
  components: {
    [Toast.name]: Toast,
  },
  data() {
    return {
      name: "",
      card: "",
    };
  },
  methods: {
    toLogin() {
      let obj = {
        name: this.name,
        id_number: this.card,
      };
      login(obj).then((res) => {
        if (res.code == 1) {
          localStorage.setItem("token", res.data.token);
          this.$router.push("/index");
        } else {
          Toast.fail(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.login {
  text-align: center;
  .bg {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
  }
  .tit1 {
    width: 80%;
    margin: 20px 0;
  }
  .tit2 {
    width: 75%;
    margin: 20px 0;
  }
  .input-item {
    width: 75%;
    margin: 0 auto;
    font-size: 16px;
    margin-top: 30px;
    div {
      color: white;
      width: 35%;
      text-align: left;
    }
    input {
      width: 65%;
      border-radius: 20px;
      padding: 8px 20px;
      border: none;
      font-size: 14px;
    }
  }
  .login-btn {
    background: linear-gradient(to bottom, #ffff5c, #f77b00);
    color: white;
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    line-height: 2.4;
    border-radius: 20px;
    width: 70%;
    margin: 60px auto 0;
  }
}
.tips{
  position: absolute;
  width: 100%;
  color: white;
  background: #0037cf93;
  bottom: 0;
  left: 0;
  font-size: 12px;
  line-height: 2.5;
}
</style>